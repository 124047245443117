<template>
  <div>
    <Settings
      :entity-type="'page'"
      :entity-label="labels.page || ''"
      :entity-path-name="'pages'"
      :required-fields="requiredFields"
    />
  </div>
</template>

<script>
import Settings from "@/views/Dashboard/Common/Settings";

import { mapState, mapGetters } from "vuex";

export default {
  components: {
    Settings,
  },
  data() {
    return {
      requiredFields: {
        title: {
          type: "text",
          label: "Title",
        },
        description: {
          type: "textarea",
          label: "Description",
        },
        permalink: {
          type: "text",
          label: "URL",
        },
        keywords: {
          type: "text",
          label: "Keywords",
        },
        image: {
          type: "image",
          label: "Image",
        },
        og_title: {
          type: "text",
          label: "Open Graph Title",
        },
        og_description: {
          type: "textarea",
          label: "Open Graph Description",
        },
        og_image: {
          type: "image",
          label: "Open Graph Image",
        },
      },
    };
  },
  computed: {
    ...mapState("helper", {
      current_language: "current_language",
      basicDataLoaded: "basicDataLoaded",
      features: "features",
    }),
    ...mapGetters("helper", {
      hasPermissions: "hasPermissions",
      labels: "labels",
    }),
  },
  watch: {
    basicDataLoaded(newValue) {
      // Means basic data loaded, running all needed stuff
      if (newValue) this.processPage();
    },
    admin_roles() {
      if (!this.isAllowed) {
        this.$router.push({ name: "dashboard" });
      }
    },
  },
  beforeMount() {
    // Redirect if user don't have permissions

    if (!this.isAllowed && this.admin_roles) {
      this.$router.push({ name: "dashboard" });
    }
  },
  mounted() {
    this.processPage();
  },
  methods: {
    processPage() {
      // if (!this.basicDataLoaded) return;

      // if (!this.features.websystem || !this.hasPermissions(["super", "web"])) {
      //   this.$router.push({ name: "dashboard" });
      //   return;
      // }
    },
  },
};
</script>

<style scoped></style>
