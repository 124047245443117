<template>
  <div class="view content-padding">
    <EditPageContainer :order="pageContainerOrder" :sidebarWidth="sidebarWidth">
      <template v-slot:header>
        <HeaderLabel
          :label-buttons="labelButtons"
          :buttons-list="buttonsList"
          :title="`${entityLabel} ${labels.title}:`"
          :entity-label="title"
        />
      </template>
      <template v-slot:body>
        <BaseCard
          v-if="Object.keys(primarySettings).length"
          padding="25px 25px 10px 25px"
          margin="0"
        >
          <div v-for="(item, key) in primarySettings" :key="key">
            <FormulateInput
              v-if="requiredFields[key]"
              v-model="singleEntity[key]"
              :name="key"
              :type="requiredFields[key].type"
              :label="requiredFields[key].label"
              validation="required"
            />
          </div>
        </BaseCard>

        <ArchiveCard
          v-if="currentUserCan('archive')"
          :labels="labels"
          :title="primarySettings.title"
          @archive="archiveEntity"
          :type="labels[entityType]"
        />
      </template>
      <template v-slot:sidebar>
        <BaseCard
          v-if="Object.keys(sidebarSettings).length"
          padding="25px 25px 10px 25px"
          margin="0"
        >
          <div v-for="(item, key) in sidebarSettings" :key="key">
            <FormulateInput
              v-if="requiredFields[key]"
              v-model="singleEntity[key]"
              :name="key"
              :type="requiredFields[key].type"
              :label="requiredFields[key].label"
              validation="required"
            />
          </div>
        </BaseCard>
      </template>
    </EditPageContainer>

    <router-view :name="`single_${entityType}_settings_notes`"></router-view>
    <router-view
      :name="`edit_access_authorization_${entityTypeUC}`"
    ></router-view>
    <router-view
      :name="`${entityType}_add_tag`"
      v-bind:kind="entityType"
      v-on:reloadData="
        getSingleEntityWrapper({
          id: $route.params.id,
          fieldsToRefresh: ['tags'],
        })
      "
      v-if="currentUserCan('tagging')"
    />
  </div>
</template>

<script>
import prepareForm from "@/services/prepare-formdata";

import { mapState, mapActions, mapGetters } from "vuex";

import { library } from "@fortawesome/fontawesome-svg-core";

const moment = require("moment");

import {
  faBook,
  faCog,
  faTrash,
  faCheck,
  faSignInAlt,
  faExternalLinkAlt,
} from "@fortawesome/free-solid-svg-icons";

library.add(faBook, faCog, faTrash, faCheck, faSignInAlt, faExternalLinkAlt);

export default {
  name: "GeneralSettings",
  components: {
    HeaderLabel: () => import("@/components/Labels/HeaderLabel"),
    BaseCard: () => import("@/components/Cards/BaseCard"),
    EditPageContainer: () =>
      import("@/components/Containers/EditPageContainer.vue"),
    ArchiveCard: () => import("@/components/Cards/ArchiveCard"),
  },
  props: {
    cptName: {
      type: String,
      default: "",
    },
    entityType: {
      type: String,
      required: true,
    },
    entityLabel: {
      type: String,
      required: true,
    },
    entityPathName: {
      type: String,
      required: true,
    },
    hasTags: {
      type: Boolean,
      default: true,
    },
    notRevisionableFields: {
      type: Array,
      default: () => {},
    },
    requiredFields: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loadedData: false,
      changedSettings: [],
      saveBtnDisabled: false,
      showXActions: false,
      originalSingleEntity: {},
      xFormStatus: "",
      userSearch: "",
      valuesLoaded: {},
      entities: {},
      sidebarSettingsInputs: ["image", "og_image"],
    };
  },
  mounted() {
    let self = this;
    this.getSingleEntityWrapper({ id: this.id }, () => {
      self.loadedData = true;

      let bindScrollFn = function () {
        let element = document.querySelector(".user-panel-card .pim-card-body");
        if (element) {
          clearInterval(bindScrollInterval);
          element.addEventListener("scroll", function () {
            if (
              self.scrollTop + self.clientHeight === self.scrollHeight &&
              self.userPanel.pages.hasNext
            ) {
              self.userPanel.currentPage = self.userPanel.currentPage + 1;
            }
          });
        }
      };

      let bindScrollInterval = setInterval(bindScrollFn, 500);
    });
  },
  computed: {
    ...mapGetters("helper", ["labels"]),
    ...mapState("blockEditor", {
      singleEntity: "singleEntity",
    }),
    ...mapState("settings", {
      options: "options",
    }),

    pageContainerOrder() {
      if (!Object.keys(this.sidebarSettings).length)
        return { header: "1", body: "2", sidebar: false };

      return { header: "1", body: "2", sidebar: "3" };
    },

    sidebarWidth() {
      // 362px is default of EditPageContainer component prop, if we don't have sidebar settings - no need to show it
      if (!Object.keys(this.sidebarSettings).length) return "0px";

      return "362px";
    },

    tagSelect() {
      return {
        btnUrl: `/dashboard/${this.entityPathName}/${this.$route.params.id}/settings/add-tag`,
        addLabel: this.labels.add || "",
        displayRemoveBtn: true,
        removeFN: this.removeTag,
      };
    },
    entityTypeUC() {
      return (
        this.entityType[0].toUpperCase() +
        this.entityType.slice(1, this.entityType.length)
      );
    },
    id() {
      return this.$route.params.id;
    },
    title() {
      if (
        this.originalSingleEntity &&
        this.originalSingleEntity.title &&
        this.originalSingleEntity.title.value
      )
        return this.originalSingleEntity.title.value;

      return `${this.entityLabel}`;
    },
    createTime() {
      if (this.singleEntity && this.singleEntity.created)
        return moment(this.singleEntity.created.time).format(
          "DD.MM.YYYY - hh:mm"
        );

      return "";
    },
    lastModifyTime() {
      if (this.singleEntity && this.singleEntity.updated)
        return moment(this.singleEntity.updated.time).format(
          "DD.MM.YYYY - hh:mm"
        );

      return "";
    },
    tags() {
      let self = this;
      if (this.singleEntity && this.singleEntity.tags) {
        let tagData = JSON.parse(JSON.stringify(this.singleEntity.tags));
        tagData.forEach((item, index) => {
          let re = new RegExp("^" + self.entityType + "-", "g");
          tagData[index].slug = tagData[index].slug.replace(re, "");
        });
        return tagData;
      } else {
        return [];
      }
    },
    labelButtons() {
      return [
        {
          label: this.cptName.length ? this.cptName : this.entityType,
          icon: "",
          show: true,
          action: () => {},
          disabled: false,
          class: "",
          link: `/dashboard/${this.entityPathName}/${this.id}/`,
          titleAttribute: this.entityType,
        },
      ];
    },
    buttonsList() {
      return [
        {
          label: `${this.labels.save} ${this.labels.settings.toLowerCase()}`,
          disabled: false,
          titleAttribute: "Save Settings",
          action: this.editEntity,
        },
        {
          label: "",
          icon: "external-link-alt",
          link: this.permalink(),
          disabled: false,
          show: this.isPageActive && !this.cptName?.length,
          titleAttribute: this.labels.preview,
        },
        {
          label: "",
          icon: "cog",
          show: true,
          disabled: false,
          class: "current",
          titleAttribute: this.labels.general_settings,
        },
      ];
    },
    isPageActive() {
      if (this.singleEntity.active) return true;
      return false;
    },
    primarySettings() {
      let result = {};

      for (const [key, value] of Object.entries(this.singleEntity)) {
        if (!this.sidebarSettingsInputs.includes(key)) result[key] = value;
      }

      return result;
    },
    sidebarSettings() {
      let result = {};

      for (const [key, value] of Object.entries(this.singleEntity)) {
        if (this.sidebarSettingsInputs.includes(key)) result[key] = value;
      }

      return result;
    },
  },
  methods: {
    archiveEntity() {
      this.$store
        .dispatch(`${this.entityType}/archive`, { id: this.$route.params.id })
        .then((res) => {
          if (res.success) {
            this.addNotification({
              variant: "success",
              msg: `${
                this.primarySettings.title
              } ${this.labels.archived.toLowerCase()}`,
            });

            this.$router.push({ name: `overview-${this.entityType}` });
          } else {
            this.addNotification({
              variant: "danger",
              msg: res.error,
              labels: "res.error",
            });
          }
        });
    },
    getEntityForEditor(payload) {
      return this.$store.dispatch(
        `${this.entityType}/get${this.entityTypeUC}ForEditor`,
        payload
      );
    },
    updateEntity(payload) {
      return this.$store.dispatch(
        `${this.entityType}/update${this.entityTypeUC}`,
        payload
      );
    },
    userSearchQuery() {
      return this.userPanel.searchQuery(
        this.userPanel.dataArray,
        this.userSearch
      );
    },
    ...mapActions("user", {
      getSelectedUsers: "getSelectedUsers",
    }),
    ...mapActions("notification", {
      addNotification: "addNotification",
    }),
    permalink() {
      if (
        this.singleEntity &&
        this.singleEntity.permalink &&
        this.singleEntity.permalink.value
      ) {
        let cptSlug = "";

        if (
          this.singleEntity.cpt_settings &&
          this.singleEntity.cpt_settings.values &&
          this.singleEntity.cpt_settings.values.base_path
        ) {
          cptSlug = this.singleEntity.cpt_settings.values.base_path;
          if (!cptSlug.startsWith("/")) {
            cptSlug = "/" + cptSlug;
          }
        }

        if (!this.singleEntity.permalink.value.startsWith("/")) {
          return cptSlug + "/" + this.singleEntity.permalink.value;
        }
        return cptSlug + this.singleEntity.permalink.value;
      }
      return "#";
    },

    // edits tag attached(either delete/add action and tag name)
    editTag(payload) {
      let self = this;
      return new Promise((resolve) => {
        this.$store
          .dispatch(`${self.entityType}/editTag`, payload)
          .then((res) => {
            resolve(res);
          });
      });
    },
    // this function makes an api call to get single entity object and to refresh fields in originalSingleEntity variable(used for storing 'live' version of object)
    getSingleEntityWrapper(payload, callback) {
      let self = this;
      // append 'settings' mode to get needed fields for settings page
      payload.mode = "settings";

      this.getEntityForEditor(payload)
        .then((res) => {
          // loop through the fields that need to be refreshed and remove them from 'changedSettings' array.
          if (
            payload.fieldsToRefresh &&
            Array.isArray(payload.fieldsToRefresh)
          ) {
            for (let i = 0; i < self.changedSettings.length; i++) {
              if (
                payload.fieldsToRefresh.includes(self.changedSettings[i].key)
              ) {
                self.changedSettings.slice(i, 1);
              }
            }
            // force refresh updated fields
            payload.fieldsToRefresh.forEach((field) => {
              self.originalSingleEntity[field] = self.singleEntity[field];
            });
          }
          // if there are no fields to refresh provided, then update entire object and re-declare changedSettings array as an empty array
          else {
            self.originalSingleEntity = JSON.parse(
              JSON.stringify(self.singleEntity)
            );
            self.changedSettings = [];
          }

          if (typeof callback === "function") callback(res);
        })
        .catch((err) => console.log(err));
    },

    editEntity() {
      let self = this;

      let payload = {};

      for (const fieldName in this.requiredFields) {
        if (Object.hasOwnProperty.call(this.requiredFields, fieldName)) {
          payload[fieldName] = this.singleEntity[fieldName];
        }
      }

      this.updateEntity(prepareForm({ payload: payload, id: this.id })).then(
        (res) => {
          if (res.success) {
            self.addNotification({
              variant: "success",
              msg: this.labels.object_has_been_updated,
            });
          } else if (res.error) {
            res.error.forEach((err) => {
              if (this.labels && this.labels[err]) {
                self.addNotification({
                  variant: "danger",
                  msg: this.labels[err],
                });
              } else {
                self.addNotification({ variant: "danger", msg: err });
              }
            });
          }
        }
      );
    },

    removeTag(tagTitle) {
      let self = this;

      let data = {
        id: this.id,
        tag: tagTitle,
        action: "delete",
      }
        .then((label) => {
          self
            .editTag(data)
            .then((res) => {
              if (res.success) {
                self.getSingleEntityWrapper(
                  { id: self.id, fieldsToRefresh: ["tags"] },
                  null
                );
                self.addNotification({
                  variant: "success",
                  msg: this.labels.tag_removed,
                });
              } else if (res.error) {
                res.error.forEach((err) => {
                  if (label[err]) {
                    self.addNotification({
                      variant: "danger",
                      msg: label[err],
                    });
                  } else {
                    self.addNotification({ variant: "danger", msg: err });
                  }
                });
              }
            })
            .catch((err) => console.error(err));
        })
        .catch((err) => console.error(err));
    },
    currentUserCan(action) {
      // if( this.singleEntity && this.singleEntity.permissions && this.singleEntity.permissions[action] && this.singleEntity.permissions[action] == 1 )
      // {
      // 	return true;
      // }

      // return false;

      // Check needs to be reworked
      console.log("Checking action:", action);

      return true;
    },
    userListMethod(page) {
      let self = this;

      this.getSelectedUsers({ page: page, collection: self.entityTypeUC })
        .then(function (data) {
          if (data) {
            data = data.data;
            self.userPanel.pages = data.pages;
            self.userPanel.dataArray.push(...data.data);
          }
        })
        .catch((error) => console.log(error));
    },
  },
  // beforeMount() {
  // 	this.setSingleEntity( {
  // 		body:{
  // 			value:{
  // 				data: {},
  // 				structure: [],
  // 				slots: {}
  // 			}
  // 		}
  // 	} );
  // },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.view {
  &-users {
    &__search {
      margin-left: auto;
      position: relative;

      &::before {
        position: absolute;
        content: "";
        left: 50px;
        width: 1px;
        height: calc(100% - 14px);
        top: 50%;
        transform: translateY(-50%);
        background: $light_gray;
      }

      .search-icon {
        position: absolute;
        left: 0;
        top: 50%;
        cursor: pointer;
        transform: translateY(-50%);
        color: $light_text;
        width: 48px;
        height: 100%;
        padding-left: 15px;
        padding-right: 15px;
        transition: all 0.2s ease;

        &:hover {
          color: $gray_text_2;
        }
      }

      ::v-deep {
        .formulate-input {
          margin-bottom: 0;

          input {
            padding-right: 30px;
            border: 1px solid #f0f0f0;
            background: $white;
            border-radius: 25px;
            padding: 15px 15px 15px 65px;
            font-family: "Lato";
            font-weight: normal;
            font-size: 0.875rem;
            line-height: 100%;
            color: $placeholder_color;

            &::placeholder {
              color: $light_text;
              font-size: 0.875rem;
            }
          }
        }
      }

      hr {
        margin: 12.5px 0;
        border-color: $light_gray;
      }
    }
  }
  &-archive {
    ::v-deep {
      input {
        font-size: 1rem;
      }
    }
  }

  ::v-deep {
    .base-card {
      .formulate-input {
        margin-bottom: 15px;

        .formulate-input-element {
          max-width: unset;
        }
      }
    }
  }
}
</style>
